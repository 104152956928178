import { ability } from '@/plugins/ability';
import config from '@/config';
import authService from '@/service/auth.service';
import userProfileService from '@/service/user-profile.service';

const module = {
  namespaced: true,
};

module.state = {
  configMode: config.mode,
  configVersion: config.version,
  userInfo: {},
  rolesAndRanks: [],
  isVendorWithUpdatePermissions: false,
};

module.mutations = {
  SET_USER_INFO(state, value) {
    state.userInfo = value;
  },
  SET_ROLES_AND_RANKS(state, value) {
    state.rolesAndRanks = value;
  },
  SET_CASL_PERMISSION(state, { casl, value }) {
    state[casl] = value;
  },
};

module.actions = {
  async getUserInfo(context) {
    const results = await userProfileService.getUserInfo();
    context.commit('SET_USER_INFO', results);
  },
  async loadUserCaslRules() {
    const rules = await authService.getUserCaslRules();
    ability.update(rules || []);
  },
  async unlinkLoginProvider(context, provider = '') {
    provider = provider.toLowerCase();
    await authService.unlinkLoginProvider(provider);
    const { userInfo } = context.state;
    userInfo.identities = userInfo.identities?.filter(
      (i) => i.identityProvider !== provider,
    );
    context.commit('SET_USER_INFO', userInfo);
  },
  async getAllRolesAndRanks(context) {
    const results = await authService.getRolesAndRanks();
    context.commit('SET_ROLES_AND_RANKS', results);
  },
  updateCaslPermissionState(context, { casl, value }) {
    context.commit('SET_CASL_PERMISSION', { casl, value });
  },
};

module.getters = {
  hasUserInfo: (state) =>
    state.userInfo != null && Object.keys(state.userInfo).length > 0,
  isNotProd: (state) => state.configMode !== 'production',
  isProd: (state) => state.configMode === 'production',
  isLocal: (state) => state.configMode === 'local',
  currentUser: (state) => state.userInfo,
  currentUserId: (state) => state.userInfo?.id,
  currentUserVendorId: (state) => state.userInfo?.vendors?.[0]?.id,
  hasAccessToMultipleCustomers: (state) =>
    state.userInfo.customerSettings?.filter(
      (cs) => cs.customer.status !== 'Churned',
    )?.length > 1,
  customerSettings: (state) => state.userInfo.customerSettings,
  selectedCustomerSetting: (state, getters, rootState, rootGetters) =>
    getters.customerSettings.find(
      (cs) => cs.customerId === rootGetters['customer/selectedCustomer']?.id,
    ),
  // leaving this hardcoded first customer setting because it is only used for initial setup/login
  currentUserCustomerId: (state) =>
    state.userInfo?.customerSettings?.[0]
      ? state.userInfo?.customerSettings?.[0].customerId
      : null,
  canAccessRequestReporting: (state, getters) =>
    !getters.selectedCustomerSetting?.customer?.isSelfService,
  canAccessInvoiceReporting: (state, getters) =>
    getters.selectedCustomerSetting?.customer?.canAccessInvoiceReporting,
  canAccessInsightsReporting: (state, getters) =>
    getters.selectedCustomerSetting?.customer?.canAccessInsightsReporting ||
    getters.selectedCustomerSetting?.customer?.isSelfService,
  currentUserRoles: (state) =>
    state.userInfo?.sspRoles?.map((r) => r.sspRole) ?? [],
  isBohUser: (state) => state.userInfo?.isBohUser,
  isLevelTwoOrUp: (state) => state.userInfo.highestRoleRank <= 2,
  gmAndAboveUser: (state) => state.userInfo.highestRoleRank <= 4,
  isInternalTechUser: (state, getters) =>
    !!getters.currentUserRoles.find((role) => role === 'Internal Technician'),
  customerRolesUserCanEdit: (state) => {
    const userRank = state.userInfo.highestRoleRank;
    const mappedRoles = Object.entries(state.rolesAndRanks)
      // eslint-disable-next-line no-unused-vars
      ?.filter(([key, value]) => value.userTypes.includes('person'))
      ?.map(([key, value]) => ({
        label: key,
        value: key,
        disabled: value.rank < userRank,
      }));
    return mappedRoles;
  },
  vendorRolesUserCanEdit: (state) => {
    const userRank = state.userInfo.highestRoleRank;
    const mappedRoles = Object.entries(state.rolesAndRanks)
      // eslint-disable-next-line no-unused-vars
      ?.filter(([key, value]) => value.userTypes.includes('vendor'))
      ?.map(([key, value]) => ({
        label: key,
        value: key,
        disabled: value.rank < userRank,
      }));
    return mappedRoles;
  },
  vendorOnlyUser(state) {
    return (
      // leaving this hardcoded first customer setting
      state.userInfo?.customerSettings?.[0] == null &&
      state.userInfo?.vendors.length
    );
  },
  canViewDashboard(state, getters, rootState, rootGetters) {
    return (
      (getters.gmAndAboveUser || getters.isInternalTechUser) &&
      !rootGetters['customer/isSelfServiceCustomer'] &&
      !getters.vendorOnlyUser
    );
  },
  isVendorWithUpdatePermissions(state) {
    return state.isVendorWithUpdatePermissions;
  },
};

export default module;
